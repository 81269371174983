.popup__input-error {
  display: none;
  align-self: flex-start;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.popup__input-error:last-of-type {
  margin-bottom: 30px;
}
