.popup__button-save {
  width: 358px;
  height: 50px;
  margin: 18px auto 0;
  padding: 0;
  background: #000000;
  border: none;
  border-radius: 2px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
.popup__button-save:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease 0s;
}
@media screen and (max-width: 620px) {
  .popup__button-save {
    font-size: 14px;
    line-height: 17px;
    width: 238px;
    height: 46px;
    margin-top: 45px;
  }
}
