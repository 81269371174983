.profile__button-edit {
  background-image: url(../../../images/profile__button-edit.svg);
  background-position: center;
  background-repeat: no-repeat;
  align-self:flex-end;
  margin-bottom: 9px;
  margin-right: auto;
  margin-left: 20px;
  width: 24px;
  height: 24px;
  background-color: #000000;
  border: 1px solid #FFFFFF;
  cursor: pointer;
}
.profile__button-edit:hover {
  opacity: 0.6;
  transition: opacity 0.4s ease 0s;
}
@media screen and (max-width: 900px) {
  .profile__button-edit {
    margin-bottom: 6px;
  }
}
@media screen and (max-width: 620px) {
  .profile__button-edit {
    position: absolute;
    top: 8px;
    right: 18px;
    width: 18px;
    height: 18px;
  }
}

