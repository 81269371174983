.cards__text {
  max-width: 215px;
  margin: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
