.profile__button-avatar{
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.profile__button-avatar:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(42,44,47, .3);
  transition: 0.3s;
  position: absolute;
  border-radius: 50%;
  background-image: url(../../../images/profile__button-avatar.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

