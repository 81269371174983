.popup__form {
  border: none;
  width: 358px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 37px;
  padding: 0;
}
@media screen and (max-width: 620px) {
  .popup__form {
    width: 238px;
    margin-bottom: 25px;
  }
}
