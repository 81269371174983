.profile__info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 340px;
}
.profile__info:nth-child(2) {
  margin-right: auto;
  margin-left: 30px;
}
@media screen and (max-width: 900px) {
  .profile__info {
    width: 282px;
  }
  .profile__info:nth-child(2) {
    margin: auto;
  }
}
@media screen and (max-width: 620px) {
  .profile__info {
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .profile__info:nth-child(2) {
    margin: 30px auto 0;
  }
}

