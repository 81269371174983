.popup__input {
  margin: 0 auto 0;
  padding-bottom: 10px;
  width: 354px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  
}
.popup__input:last-of-type {
  margin-top: 15px;
}
@media screen and (max-width: 620px) {
  .popup__input {
    width: 234px;
    margin-top: 75px;
  }
}
