.popup__name-form {
  width: 358px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 34px auto 48px;
  /* outline: 1px solid red; */
  text-align: center;
}
@media screen and (max-width: 620px) {
  .popup__name-form {
    width: 238px;
    font-size: 18px;
    line-height: 22px;
    align-self: center;
    margin-top: 25px;
  }
}
