.popup__container {
  position: relative;
  /* margin-top: 220px; */
  width: 420px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
@media screen and (max-width: 620px) {
  .popup__container {
    width: 282px;
    margin-top: 115px;
  }
}
