.header__logo {
  background-image: url(../../../images/header__logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 142px;
  height: 33px;
  color: #FFFFFF;
}
@media screen and (max-width: 620px) {
  .header__logo {
    width: 103px;
    height: 24px;
    margin-left: 27px;
  }
}
