.profile__name {
  margin: 0;
  max-width: 295px;
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  color: #FFFFFF;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .profile__name {
    max-width: 230px;
    font-size: 27px;
    line-height: 33px;
  }
}
@media screen and (max-width: 620px) {
  .profile__name {
    text-align: center;
    width: 185px;
  }
}

