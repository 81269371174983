.header {
  max-width: 880px;
  min-height: 74px;
  display: flex;
  justify-content: space-between; 
  margin: 45px auto 0;
  border-bottom: 1px solid rgba(84, 84, 84, .7);
}
@media screen and (max-width: 900px) {
  .header {
    max-width: 560px;
  }
}
@media screen and (max-width: 620px) {
  .header {
    position: relative;
    margin-top: 28px;
    max-width: 320px;
    min-height: 56px;
  }
}
