.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 880px;
  min-height: 120px;
  margin: 40px auto 0;
}
@media screen and (max-width: 900px) {
  .profile {
    max-width: 560px;
  }
}
@media screen and (max-width: 620px) {
  .profile {
    flex-direction: column;
    max-width: 282px;
  }
}
