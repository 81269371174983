.profile__description {
  margin: 6px 0 0;
  width: 288px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .profile__description {
    width: 282px;
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 620px) {
  .profile__description {
    text-align: center;
  }
}