.profile__button-add {
  background-image: url(../../../images/profile__button-add.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 150px;
  height: 50px;
  background-color: #000000;
  border: 2px solid #FFFFFF;
  cursor: pointer;
}
.profile__button-add:hover {
  opacity: 0.6;
  transition: opacity 0.4s ease 0s;
}
@media screen and (max-width: 900px) {
  .profile__button-add {
    width: 120px;
    height: 50px;

  }
}
@media screen and (max-width: 620px) {
  .profile__button-add {
    width: 282px;
    height: 50px;
    margin: 36px auto 0;
  }
}
